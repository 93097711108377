/** @jsx jsx */

import { graphql } from 'gatsby'
import { FC, useEffect, useRef } from 'react'
import { jsx } from 'theme-ui'

import BlogPostTeaserCard from '../../../components/blog-post-teaser-card'
import Halo from '../../../components/halo'
import Intro from '../../../components/intro'
import Layout from '../../../components/layout'
import UniversalLink from '../../../components/universal-link'
import routes from '../../../constants/routes'

interface TagTemplateProps {
  data: any
}

const TagTemplate: FC<TagTemplateProps> = ({ data }) => {
  const tagsRef = useRef(null)

  useEffect(() => {
    let el

    tagsRef.current.querySelectorAll(`li > a`).forEach((tag) => {
      if (tag.innerText === name) el = tag
    })

    el.focus()
  }, [tagsRef])

  const { name } = data.contentfulTag
  const tags = data.allContentfulTag.edges

  const posts = data.allContentfulEntry.edges
    .filter(({ node: { title, tags } }) => title && tags.includes(name))
    .sort((a, b) => {
      const aDate = new Date(a.node.date)
      const bDate = new Date(b.node.date)

      return bDate.getTime() - aDate.getTime()
    })

  return (
    <Layout>
      <Halo
        title={`${data.allContentfulPageMetadata.edges[0].node.title} | Posts tagged with ${name}`}
        description={data.allContentfulPageMetadata.edges[0].node.description}
        root="../../../"
        route={`${routes.contentCenter}/tags/${name.toLowerCase().replace(/\ /g, `-`)}`}
      />
      <Intro title={data.allContentfulPageMetadata.edges[0].node.title} description={`Posts tagged with ${name}`} />

      <section sx={{ bg: `white_slate8` }}>
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4, py: 5 }}>
          <ul
            id="tags"
            sx={{
              pl: 0,
              mt: -3,
              mb: 0,
              pt: 3,
              listStyleType: `none`,
            }}
            ref={tagsRef}
          >
            <li
              sx={{
                mb: 3,
                mr: 3,
                display: `inline-block`,
              }}
            >
              <UniversalLink
                sx={{
                  display: `block`,
                  borderRadius: 6,
                  px: 3,
                  py: 1,
                  textDecoration: `none`,
                  bg: `gray0_slate9`,

                  [`:hover, :focus`]: {
                    bg: `gray1_black`,
                  },
                }}
                to={routes.contentCenter}
              >
                All
              </UniversalLink>
            </li>

            {tags.map(({ node }) => (
              <li
                key={node.name}
                sx={{
                  mb: 3,
                  mr: 3,
                  display: `inline-block`,
                }}
              >
                <UniversalLink
                  sx={{
                    display: `block`,
                    borderRadius: 6,
                    px: 3,
                    py: 1,
                    textDecoration: `none`,
                    bg: name === node.name ? `primary` : `gray0_slate9`,
                    color: name === node.name ? `white_black` : undefined,

                    [`:hover, :focus`]: {
                      bg: name === node.name ? undefined : `gray1_black`,
                    },
                  }}
                  to={`${routes.contentCenter}/tags/${node.name.toLowerCase().replace(/\ /g, `-`)}`}
                >
                  {node.name}
                </UniversalLink>
              </li>
            ))}
          </ul>

          <div
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              mt: 5,
              borderBottom: `1px solid`,
              borderBottomColor: `gray2_slate9`,
              pb: 2,
            }}
          >
            <h1 id={`tagged-with-${name.toLowerCase().replace(/\ /g, `-`)}`} sx={{ fontSize: 3, my: 0 }}>
              <UniversalLink
                sx={{
                  color: `inherit`,
                  textDecoration: `none`,

                  [`:hover, :focus`]: {
                    textDecoration: `underline`,
                  },
                }}
                to={`#tagged-with-${name.toLowerCase().replace(/\ /g, `-`)}`}
              >
                {name}
              </UniversalLink>
            </h1>

            <p sx={{ my: 0 }}>
              <UniversalLink
                sx={{
                  fontSize: 2,
                  fontWeight: `normal`,
                  textDecoration: `none`,

                  [`:hover, :focus`]: {
                    textDecoration: `underline`,
                  },
                }}
                to={routes.contentCenter}
              >
                See all tags
              </UniversalLink>
            </p>
          </div>

          <ul
            sx={{
              pl: 0,
              mt: 4,
              mb: 0,
              listStyleType: `none`,
              display: [`block`, null, `grid`],
              gridTemplateColumns: `repeat(2, 1fr)`,
              columnGap: 4,
              rowGap: 5,
            }}
          >
            {posts.map(({ node }, index) => {
              let to = `#`

              if (node.caseStudySlug) to = `${routes.caseStudies}/${node.caseStudySlug}`
              if (node.blogPostSlug) to = `${routes.blog}/${node.blogPostSlug}`
              if (node.link) to = node.link

              return <BlogPostTeaserCard key={node.title} {...{ node, index, to }} />
            })}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    allContentfulEntry {
      edges {
        node {
          ... on ContentfulBlogPost {
            author {
              company

              image {
                gatsbyImageData(height: 64, width: 64)
              }

              name
              slug
              title
            }

            blogPostSlug: slug

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/blog/{ContentfulBlogPost.slug}")

            heroImage {
              gatsbyImageData(width: 936)
              title
            }

            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }

          ... on ContentfulExternalPost {
            author {
              company

              image {
                gatsbyImageData(height: 64, width: 64)
              }

              name
              slug
              title
            }

            description {
              childMarkdownRemark {
                html
              }
            }

            heroImage {
              gatsbyImageData(width: 936)
              title
            }

            link
            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }

          ... on ContentfulCaseStudy {
            author {
              company

              image {
                gatsbyImageData(height: 64, width: 64)
              }

              name
              slug
              title
            }

            caseStudySlug: slug

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/case-studies/{ContentfulCaseStudy.slug}")

            heroImage {
              gatsbyImageData(width: 936)
              title
            }

            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }
        }
      }
    }

    allContentfulPageMetadata(filter: { title: { eq: "Content center" } }) {
      edges {
        node {
          title
          description
        }
      }
    }

    allContentfulTag(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }

    contentfulTag(slug: { eq: $slug }) {
      name
      slug
    }
  }
`
